<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-01 15:48:45
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-01 16:43:55
-->
<template>
  <div class=''>
    <a-modal :maskClosable="false" :title="'物流信息'" style="top: 8px;" :width="800" v-model="visible" :confirmLoading="isLoading"
      @cancel="toCancel" :footer="null">
      <a-table size="small" :data-source="tableData" bordered rowKey="id" :pagination="false">
        <a-table-column title="序号" data-index="index" :width="60" align="center" :customRender="(text,record,index)=>{return index+1}">
        </a-table-column>
        <a-table-column title="发货单号" data-index="shipCode" :width="140" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="物流信息" data-index="remark" :width="200" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="操作时间" data-index="time" :width="140" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="操作" data-index="action" :width="60" :ellipsis="true" align="center">
          <template slot-scope="text, record">
            <a class="a_link" @click="onDelete(record)">删除</a>
          </template>
        </a-table-column>
      </a-table>
      <div style="margin-top: 14px;">
        <a-form-model ref="form" :model="form" :labelCol="{span: 4}" :wrapperCol="{span:20}">
          <a-form-model-item label="物流公司">
            <div>{{deliveryName}}</div>
          </a-form-model-item>
          <a-form-model-item label="物流信息" prop="remark" :rules="[{required:true,message:'请输入物流信息',trigger:'blur'}]">
            <a-textarea placeholder="请输入物流信息" v-model.trim="form.remark"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="附件">
            <QiniuUpload type="image" :value.sync="form.imgUrl" :size="3"></QiniuUpload>
            <div class="upload-hint-text">支持扩展名：.jpg .png</div>
          </a-form-model-item>
          <div style="text-align: right;margin-top: 14px;">
            <a-button type="primary" @click="toSubmit">确认新增物流</a-button>
          </div>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
  const qs = require('qs')

export default {
  name:'',
  data() {
    return {
      isLoading: false,
      visible: false,
      tableData: [],
      deliveryName: '',
      form: {},
      shipCode: undefined
    }
  },

  computed: {

  },
  created() {
    
  },
  methods: {
    getData(row, name) {
      this.visible = true
      let postData = {
        shipCode: row.shipCode,
      }
      this.shipCode = row.shipCode
      this.form.shipCode = row.shipCode
      this.axios.get(`/api/order/system/orderShipLogistics/list?${qs.stringify(postData)}`).then(res => {
        this.tableData = res.body.logisticsList
        this.deliveryName = res.body.deliveryName
        // this.form.imgUrl = res.body.imgUrl
      })
    },

    toCancel() {
      this.$refs.form && this.$refs.form.resetFields()
      this.visible = false
    },

    // 删除物流信息
    onDelete(row) {
      var _this = this
      this.$confirm({
        title: '确定删除该条记录',
        onOk: () => {
          this.axios.post(`/api/order/system/orderShipLogistics/del/${row.id}`).then(res => {
            this.$message.success(res.message)
            this.form = {}
            _this.getData({shipCode: _this.shipCode});
          }).catch(err => {
          })
        }
      })
    },

    /**
    * 提交 
    */
    toSubmit() {
      let tmp = this.form
      this.$refs.form.validate(valid => {
        if (valid) {
          let postData = Object.assign({}, tmp)
          this.isLoading = true
          this.axios.post(`/api/order/system/orderShipLogistics/add?${qs.stringify(postData)}`).then(res => {
            this.$message.success(res.message)
            this.form = {}
            this.visible = false
          }).catch(err => {
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
</style>