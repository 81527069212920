<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="发货单号">
              <a-input v-model="searchData.shipCode" placeholder="请输入发货单号" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="订单号">
              <a-input v-model="searchData.orderCode" placeholder="请输入订单号" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="制单人">
              <a-input v-model="searchData.createUser" placeholder="请输入制单人" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="状态">
              <DictSelect
                field="shipStatus"
                :value.sync="searchData.deliveryStatus"
                style="width: 100%"
                placeholder="请选择状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="收货人">
              <a-input v-model="searchData.consignee" placeholder="请输入收货人" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="收货电话">
              <a-input v-model="searchData.phone" placeholder="请输入收货电话" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="收货地址">
              <PCD
                placeholder="收货地址"
                :province.sync="searchData.province"
                :provinceId.sync="searchData.provinceId"
                :city.sync="searchData.city"
                :cityId.sync="searchData.cityId"
                :district.sync="searchData.area"
                :districtId.sync="searchData.areaId"
              ></PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="详细地址">
              <a-input v-model="searchData.address" placeholder="请输入详细地址" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="创建日期">
              <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button
          type="primary"
          v-if="
            deliverGoods && selectedRows.length > 0 && selectedRows[0].deliveryStatus == 0 && selectedRows[0].shippingOrderStatus == 2
          "
          @click="toHandler('ship')"
          >发货</a-button
        >
        <a-button
          type="primary"
          v-if="isAudit && selectedRows.length > 0 && selectedRows[0].shippingOrderStatus == 1"
          @click="toHandler('audit')"
          >审核</a-button
        >
        <a-button
          type="primary"
          v-if="updateLogistics && selectedRows.length > 0 && selectedRows[0].deliveryStatus == 2"
          @click="toHandler('improve_logistics')"
          >完善物流</a-button
        >
        <a-button
          type="primary"
          v-if="selectLogistics && selectedRows.length > 0 && selectedRows[0].deliveryType != 0"
          @click="toHandler('view_logistics')"
          >查看物流</a-button
        >
        <a-button  type="primary" @click="toHandler('check')">查看</a-button>
        <a-button v-if="" type="primary" @click="toHandler('push_k3')">推送到K3</a-button>
        <a-button v-if="isSelect" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button v-if="isPush && selectedRows.length > 0 && selectedRows[0].deliveryStatus == 1" type="primary" @click="toHandler('push_k3')">推送到K3</a-button>
        <downLoad
          method="get"
          api="/api/order/system/orderShipInfo/excelExportOrderShipInfo"
          :params="selectedRows[0]"
          name="排货单.xlsx"
          v-if="isExportShipOrderDetail && selectedRows.length == 1"
        >导出排货单</downLoad
        >
        <downLoad v-if="isExport" method="get" api="/api/order/system/orderShipInfo/excelExportDealerOrderShipList" :params="searchData" name="经销商发货单.xls">导出发货单</downLoad>
<!--        <downLoad v-if="isExport" method="get" api="/api/order/system/orderShipInfo/excelExportDealerOrderShipList" :params="searchData" name="经销商发货单.xls">导出发货单</downLoad>-->
      </div>

      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        :scroll="{ x: 2000 }"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="flagInvoice" slot-scope="text">
          {{ text == true ? '是' : '否' }}
        </span>
        <span slot="orderCode" slot-scope="text, row">
          <a @click="checkOrderInfo(row.orderId)">{{ text }}</a>
        </span>
        <span slot="shippingOrderStatus" slot-scope="text">
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
        </span>
        <span slot="deliveryStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#e6a23c">待发货</a-tag>
          <a-tag v-if="text == 1" color="#108ee9">已发货</a-tag>
          <a-tag v-if="text == 2" color="#67c23a">已收货</a-tag>
        </span>
        <span slot="actualPrice" slot-scope="text">
          {{ text | formatMoney }}
        </span>
        <span slot="flagInvoice" slot-scope="text">
          {{ text == true ? '是' : '否' }}
        </span>
        <span slot="address" slot-scope="text, row">
          {{ row.consignee }},{{ row.phone }}<br />
          {{ row.provinceName }}{{ row.cityName }}{{ row.areaName }}{{ row.address }}
        </span>
      </a-table>

      <!-- 编辑表单 -->
      <OrderShipInfo-edit-modal ref="OrderShipInfoEditModal" @reload="getData"></OrderShipInfo-edit-modal>
      <ImproveLogistics ref="ImproveLogistics" @reload="getData"></ImproveLogistics>
      <CheckLogistics ref="CheckLogistics" @reload="getData"></CheckLogistics>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>

      <!-- 审核 -->
      <InvoiceReview ref="InvoiceReview" @reload="getData"></InvoiceReview>
    </div>
  </a-card>
</template>

<script>
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import { columns } from './components/colums.js'
import OrderShipInfoEditModal from './components/OrderShipInfoEditModal.vue'
import { delOrderShipInfo, listOrderShipInfo, selectByIdOrderShipInfo } from './api/OrderShipInfoApi'
import ImproveLogistics from './components/ImproveLogistics.vue'
import CheckLogistics from './components/CheckLogistics.vue'
import InvoiceReview from '@/views/Invoice/components/InvoiceReview.vue'
import {checkPermission} from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'orderShipInfo',
  components: {
    OrderShipInfoEditModal,
    ImproveLogistics,
    CheckLogistics,
    OrderInfoCheckModal,
    InvoiceReview
  },
  data() {
    return {
      columns: columns,
      confirmLoading: false,
      visible: false,
      rejectReason: '',
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      // page: Object.assign({}, pageSource),
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isSelect: checkPermission('invoice:select'),
      isAudit: checkPermission('invoice:audit'),
      isExport: checkPermission('invoice:export'),
      isPush: checkPermission('invoice:push'),
      deliverGoods: checkPermission('invoice:deliver_goods'),
      updateLogistics: checkPermission('invoice:update_logistics'),
      selectLogistics: checkPermission('invoice:select_logistics'),
      isExportShipOrderDetail: checkPermission('order:ship:export_ship_detail'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    //打开上游单据
    checkOrderInfo(orderId) {
      const data = {
        id: orderId,
      }
      this.$refs.OrderInfoCheckModal.isShow(data, 'check')
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listOrderShipInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */

     getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    // reset() {
    //   this.$bus.$on('reset', (res) => {
    //     this.page = Object.assign({}, this.page)
    //     this.getData(res)
    //   })
    // },
    /**
     * 更改分页
     */
     changeTable(pagination) {
      this.page = pagination
      this.getData({}, pagination)
    },

    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'ship':
          _this.$refs.OrderShipInfoEditModal.setRowData(_this.selectedRows[0], 'ship')
          break
        case 'improve_logistics':
          _this.$refs.ImproveLogistics.getData(_this.selectedRows[0], 'improve_logistics')
          break
        case 'view_logistics':
          _this.$refs.CheckLogistics.getData(_this.selectedRows[0].shipCode, 'view_logistics')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderShipInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.OrderShipInfoEditModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'audit':
          _this.$refs.InvoiceReview.show(_this.selectedRows[0], 'audit')
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
